@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/ashhome/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/ashhome/src/scss/utility/_mixin.scss";
@import "/Users/alivevn/Website/vhost/ashhome/src/scss/utility/_mymixin.scss";


#page404 {
  .taC { display: block; font-size: 20px; line-height: 30px; letter-spacing: 3px; text-align: center; margin: 20px 0 0 0;
    a { font-size: 20px;text-decoration: none; color: #00B0FF;  }
  }
  .page-title { font-size: 35px; font-weight: bold; letter-spacing: 5px; padding-bottom: 50px; text-align: center;}
}
.page_404 { padding: 300px 0 200px; }